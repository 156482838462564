<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10 pt-10">

		<div v-if="!loadingData">
			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 50px; margin-top: 30px">

				<a-col :span="24" :md="16" :lg="16" :xl="16" class="col-form">
					
					<a-card :bordered="false" class="px-5 mx-0 pt-20">
						<a-row :gutter="[24,24]" v-if="currentScreen == 0">
							<a-col :span="24">
								<a-row class="mt-0 pt-2 mb-20">
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 text-center">
										<h4>{{ formatName(quiz.title) }}</h4>
									</a-col>

									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5 pl-5">
										<label><strong>Instructions</strong></label>
									</a-col>
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-10 mb-24">
										<ul>
											<li class=""><p>{{ formatName(quiz.instruction) }}</p></li>
											<li><p>This quiz consist of <strong>{{ questions.length }} minutes</strong> complete the quiz. </p></li>
											<li><p>You have <strong>{{ quiz.duration }}</strong> questions </p></li>
										</ul>
									</a-col>
								</a-row>


								<a-row class="mt-0 pt-2 mb-20">
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 text-center">
										<a-button :loading="quiz.loading" type="primary" class="" @click="handleStartQuiz"> Start Quiz </a-button>					
									</a-col>
								</a-row>
								
							</a-col>
						</a-row>

						<a-row :gutter="[24,24]" v-if="currentScreen > 0 && currentScreen < questions.length + 1">
							<a-col :span="24">

								<a-row class="mt-0 pt-2 mb-20 px-20">
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 px-20 text-center">
											<svg class="" style="width: 24px; position: absolute; top: -5px; bottom: 0px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
											</svg>
											<br/>
											<label class="py-0 pt-20 ml-20 pl-10" style="color: #141414; font-size: 20px;">
												{{ getTimeLeft }}
											</label>
									</a-col>

									<a-col :span="24"  :md="18" :lg="18" :xl="18" class="mb-24 px-20">
										<h6>{{ quiz.title }}</h6>
									</a-col>

									<a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24 text-right pr-20">		
										<a-button type="primary" class="" @click="confirmQuizSubmission"> Finish Quiz</a-button>					
									</a-col>

									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5 pl-5 px-20">
										<a-progress :percent="percentageCompleted" stroke-color="primary" />
									</a-col>
								</a-row>

								<a-card style="background-color: #F5F5F5; margin: 20px 40px; padding: 30px 30px;">
									<a-row :gutter="[24,24]" v-for="(question, index) in questions" :key="question.uuid">
										<a-col :span="24" v-if="currentScreen == index + 1">
											<a-row class="mt-0 pt-2 mb-10">
												<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5">
													<label>Question {{ index + 1 }}</label>
												</a-col>

												<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5 pl-5">
													<h6 class="mb-0 pb-0" style="font-weight: bold;">
														<span  id="deltaValue" class="col-oder-2" v-html="quillToHtml(addOps(question.content))"></span>
													</h6>
												</a-col>
											</a-row>

											<a-row :gutter="24">
												<a-col :span="24" :md="24" :lg="24" class="px-15">
													<a-radio-group v-model="question.answer" button-style="solid">
														<a-radio-button v-for="(opt, index) in question.choices" :key="index" :value="opt.content" style="width: 100%;">
															<span style=""></span> <span  id="deltaValue" class="col-oder-2" v-html="quillToHtml(addOps(opt.content))"></span>
														</a-radio-button>
													</a-radio-group>
												</a-col>
											</a-row>
											
										</a-col>
									</a-row>
								</a-card>


								<a-row class="mt-20 pt-20 mb-20 px-20">
									<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-center pl-20">
										<a-button id="btnBordered" type="text" class="" @click="previosQuestion" v-if="currentScreen > 1"> 
											<svg class="text-primary" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
											</svg>
											Previous 
										</a-button>
									</a-col>
									<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-center pl-20">
										<a-button id="btnBordered" type="text" class="text-right" @click="nextQuestion"  v-if="currentScreen < questions.length"> 
											Next
											<svg class="text-primary ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
											</svg>
										</a-button>					
									</a-col>

								</a-row>

							</a-col>
						</a-row>

					</a-card>
				</a-col>

			</a-row>
		</div>


		<div v-if="loadingData">
			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

		
		<a-modal v-model="quiz.showModal" :footer="null" :header="null" :closable="false">

			<a-row :gutter="24" class="mt-10">
				<a-col :span="24" class="text-center" style="">
					<svg class="text-warning pt-10" style="width: 38px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V8m0 8h0m9-4a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
					</svg>
					<h6 class="" style="font-size: 20px">
						Confirm Action
					</h6>
				</a-col>
			</a-row>
			<p class="mt-3">Are you sure you want to submit this quiz?, once you submit, you can not edit answers.</p>

			<a-row :gutter="24" class="mt-10">
				<a-col :span="24" class="text-center">
					<a-button id="publishBtn" class="btn btn-white text-silabu mr-20" @click="cancelQuizSubmission">
						Cancel
					</a-button>
					<a-button id="btn" class="btn btn-silabu" :loading="quiz.loading" @click="handleSubmitQuiz">
						Yes, Submit
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>


		<a-modal v-model="quiz.showTimeoutModal" :footer="null" :header="null" :closable="false">

			<a-row :gutter="24" class="mt-10">
				<a-col :span="24" class="text-center" style="">
					<svg class="text-warning pt-10" style="width: 38px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V8m0 8h0m9-4a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
					</svg>
					<h6 class="" style="font-size: 20px">
						Alert Message
					</h6>
				</a-col>
			</a-row>

			<a-row :gutter="24" class="mt-10">
				<a-col :span="24" class="text-center" style="">
					<p class="mt-3 text-center">This is automatic submission of the quiz due to timeout, thank you</p>
				</a-col>
			</a-row>

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary"/>

				</a-col>

			</a-row>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
    var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;

	export default ({
		
		components: {
			DoubleBounce,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
                questions: [],
				classUuid: null,
				topicUuid: null,
				quizUuid: null,
				showPublishModal: false,
				message: '',

				currentScreen: 0,

				quiz: {
					title: null,
					instruction: null,
					duration: null,
					questionsCount: null,
					price: null,
					clazz: null,
					topic: null,
					educationSystems: [],
					educationLevels: [],
					educationGrades: [],
					attempts: [],
					subjects: [],
					favorites: [],
					rating: null,
					createdAt: null,
					updatedAt: null,
					uuid: null,
					attemptsCount: 0,
					thumbnail: null,
					mine: false,
					isFree: false,
					favorite: false,
					attempted: false,
					timeout: false,
					showTimeoutModal: false,
					loading: false,
					showModal: false,
				},


				userDetails: {},

				millisecondsInOneMinute: 1000 * 60,

				time: null, //in seconds
        		timer: null,
				isSubmitted: false,
			}
				
		},
		async mounted () {
			let breadcrumbs = [
				{ title: 'Classes', url: '/institutions/classes', isActive: false },
                { title: 'Classroom', url: `/institutions/my-classes/view/${this.$route.params.uuid}`, isActive: false },
                { title: 'View Topic', url: `/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}`, isActive: false },
				{ title: 'Quiz', url: `/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}/quiz/${this.$route.params.quizUuid}`, isActive: false },
				{ title: 'Room', url: ``, isActive: true },
			];
			
			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.classUuid = this.$route.params.uuid;
			this.topicUuid = this.$route.params.topicUuid;
			this.quizUuid = this.$route.params.quizUuid;
			this.getUserDetails();
			this.getQuizDetails();
			this.getQuizQuestions();
		},
		computed: {

            percentageCompleted () {
                let completedQuestions = this.questions.filter(qn => qn.answer != null);

				let percetage = ((completedQuestions.length / this.questions.length) * 100).toFixed(0);

                return percetage;
            },

			getTimeLeft () {
				return `${this.getMinutes}:${this.getSeconds}`
			},

			getMinutes () {
				return String(Math.floor(this.time / 60)).padStart(2, '0')
			},

			getSeconds () {
				return String(this.time % 60).padStart(2, '0')
			},

        },
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			decrementOrAlert () {
				if (this.time > 0) {
					this.time--
					return
				}
			
				if(this.time == 0 && !this.isSubmitted) {

					this.quiz.timeout = true;
					this.quiz.showTimeoutModal = true;

					this.isSubmitted = true

					this.handleSubmitQuiz();
				}

				// clearInterval(this.timer)
			},


			quillToHtml(deltaOps) {

				const jsonDeltaOps = JSON.parse(deltaOps)

				const delta = jsonDeltaOps.ops

				var cfg = {};

				var converter = new QuillDeltaToHtmlConverter(delta, cfg);

				var html = converter.convert();

				return html;
			},


			addOps(value) {
				let defaultStarter = '{\"ops\":';
				let defaultFinisher = '}';

				let formattedValue = `${defaultStarter}${value}${defaultFinisher}`;

				return formattedValue;

			},


			removeOps(value) {

				let trimmedValue = ''

				if(value != null && value.includes('{\"ops\":')) {

					let value2 = value.replace('{\"ops\":', '')

					trimmedValue = value2.substring(0, value2.length - 1);
					
				}else{
					trimmedValue = value;
				}

				return trimmedValue
			},


			getLabel(index) {
                if(index == '0') {
                    return 'a';
                }else if(index == '1') {
                    return 'b';
                }else if(index == '2') {
                    return 'c';
                }else if(index == '3') {
                    return 'd';
                }else if(index == '4') {
                    return 'e';
                }else if(index == '5') {
                    return 'f';
                }else if(index == '6') {
                    return 'g';
                }else if(index == '7') {
                    return 'h';
                }else {
                    return 'a';
                }
            },
			

			launchModal() {
				this.question.showModal = true;
			},


			async getUserDetails() {

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.userDetails = userDetails;
					
				}
			},

			async getQuizQuestions() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/quizzes/questions?quiz=${this.$route.params.quizUuid}&orderBy=position&order=ASC`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200)  {

						if(response.data.data.length > 0) {
							this.questions = response.data.data;

							// await localStorage.setItem('questionPosition', response.data.length + 1)

						}else{
							this.message = 'No questions available'
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getQuizDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/quizzes?uuid=${this.$route.params.quizUuid}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status >= 200 && response.status < 210)  {

						if(response.data.data.title != null) {
							this.quiz.uuid = response.data.data.uuid;
							this.quiz.title = response.data.data.title;
							this.quiz.instruction = response.data.data.instruction;
							this.quiz.duration = response.data.data.duration;
							this.quiz.questionsCount = response.data.data.questionsCount;
							this.quiz.price = response.data.data.price;
							this.quiz.clazz = response.data.data.clazz;
							this.quiz.topic = response.data.data.topic;
							this.quiz.educationSystems = response.data.data.educationSystems;
							this.quiz.educationLevels = response.data.data.educationLevels;
							this.quiz.educationGrades = response.data.data.educationGrades;
							this.quiz.attempts = response.data.data.attempts;
							this.quiz.subjects = response.data.data.subjects;
							this.quiz.favorites = response.data.data.favorites;
							this.quiz.rating = response.data.data.rating;
							this.quiz.createdAt = response.data.data.createdAt;
							this.quiz.updatedAt = response.data.data.updatedAt;
							this.quiz.attemptsCount = response.data.data.attemptsCount;
							this.quiz.thumbnail = response.data.data.thumbnail;
							this.quiz.mine = response.data.data.mine;
							this.quiz.isFree = response.data.data.isFree;
							this.quiz.favorite = response.data.data.favorite;
							this.quiz.attempted = response.data.data.attempted;

							this.time = response.data.data.duration * 60;
						}

                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			handleStartQuiz() {
				this.currentScreen = 1;

				this.timer = setInterval(this.decrementOrAlert, 1000)
			},

			onSelectAnswer(event, index) {
				// this.questions[index].answer = event.target.value
			},

			nextQuestion() {
				if(this.currentScreen <= this.questions.length) {
					this.currentScreen++;
				}
			},


			previosQuestion() {
				if(this.currentScreen > 1) {
					this.currentScreen--;
				}
			},


			confirmQuizSubmission() {
				this.quiz.showModal = true;
			},

			cancelQuizSubmission() {
				this.quiz.showModal = false;
			},

			async handleSubmitQuiz() {

				this.quiz.loading = true;

				let answers = []

				this.questions.forEach(qn => {
					answers.push({
						content: this.removeOps(qn.answer),
						quiz: this.$route.params.quizUuid,
						question: qn.uuid,
						user: this.userDetails.uuid
					})
				});

				console.log(answers)

				let url = `${this.$BACKEND_URL}/quizzes/attempts`;

				// this.quiz.showPublishModal = false;

				this.$AXIOS.post(url, answers).then(async(response) => {
					
					this.notify('Quiz has been successfully submitted', 'success')

					this.quiz.loading = false;

					this.quiz.showTimeoutModal = false;

					this.$router.push(`/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}/quiz/${this.$route.params.quizUuid}/results`)


				}).catch(async(error) => {

					console.log(error)
					
					this.quiz.loading = false;

					this.notify('Something went wrong, please try again!!!', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},


			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#publishBtn, #publishBtn:hover, #publishBtn:focus { 
	background-color: inherit !important;
	border-color: #734EBF;
	color: #734EBF;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}


p, #deltaValue, #deltaValue > p {
    display: inline-block !important;
}
/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>